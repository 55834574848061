import { createStyles, Grid, MenuItem, Tab, Tabs, Theme, Typography, Tooltip } from "@material-ui/core";
import { BallotRounded, InfoRounded } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { ControlledSelect, ControlledNumberInput, ControlledCheckbox } from "@onpreo/components";
import { PipelineDocument } from "@onpreo/database";
import { FC, useEffect, Fragment, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Value } from "sass";
import { DesignGeneral } from "../../smashleads/interfaces/Design";
import { QuestionBase } from "../../smashleads/interfaces/Question";
import { useLeadGeneratorDispatch, useLeadGeneratorState } from "../generator-state";
import { HandleForwardType } from "../types";

const useStyles = makeStyles((muiTheme: Theme) =>
    createStyles({
        container: {
            minHeight: 200,
            maxHeight: 400,
            width: "100%",
            padding: muiTheme.spacing(0)
        },
        tabs: {
            fontSize: 12,
            fontWeight: 600,
            boxShadow: "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08)",
            borderRadius: 4,
            backgroundColor: "#F7FAFC",
            opacity: 1,
            color: "#212529",
            border: "1px solid"
        },
        tabsSelected: {
            backgroundColor: "#185cff"
        },
        label: {
            fontWeight: 600,
            fontSize: "14px"
        }
    })
);

const useInfoTooltip = makeStyles(theme => ({
    arrow: {
        color: "#0071E3"
    },
    tooltip: {
        backgroundColor: "#0071E3",
        maxWidth: 400,
        padding: 16,
        textAlign: "left",
        fontWeight: 500
    }
}));

export const InfoBubble: FC<{ title: string }> = props => {
    const classes = useInfoTooltip();
    return (
        <Tooltip classes={classes} title={props.title} arrow>
            <InfoRounded style={{ color: "#0071E3", width: 20, height: 20, margin: "0 6px 2px 6px", cursor: "pointer" }} />
        </Tooltip>
    );
};

export interface QuestionTypePipeline extends QuestionBase {}

interface QuestionPipelineProps {
    question: QuestionTypePipeline;
    design: DesignGeneral;
    handleForward?: HandleForwardType;
    partnersList?: any[];
    accessedPipelines?: PipelineDocument[];
    tags?: string[];
}

const textTransformTags = ["google", "facebook", "youtube"];

const QuestionPipeline: FC<QuestionPipelineProps> = props => {
    const { question, design, partnersList, accessedPipelines, tags } = props;
    const classes = useStyles(design);
    const state = useLeadGeneratorState();
    const dispatch = useLeadGeneratorDispatch();

    const defaultValues = {
        tag: undefined,
        gsoAktion: undefined,
        days: undefined,
        voucher: undefined,
        deadline: undefined,
        currency: undefined,
        partnerId: undefined,
        chooseCustomPipeline: false,
        customPipeline: undefined
    };

    const { control, watch, setValue, reset } = useForm({
        defaultValues: {
            tab: 0,
            ...defaultValues
        }
    });

    const object = watch();

    useEffect(() => {
        const gsoAkt = object["gsoAktion"] !== undefined && object["gsoAktion"] !== "default";
        const gsoDays = object["days"] !== undefined && object["days"] !== "default";
        const gsoVoucher = object["voucher"] !== undefined && object["currency"] !== undefined && object["currency"] !== "default";
        const gsoDeadline = object["deadline"] !== undefined && object["deadline"] !== "default";
        const gsoCondition = object["tab"] === 0 && object["tag"] === "GSO" && gsoAkt && gsoDays && gsoVoucher && gsoDeadline;

        if (object["tab"] === 0 || object["tab"] === 1 || object["tab"] === 2 || (object["chooseCustomPipeline"] && object["customPipeline"])) {
            const gsoInfo =
                object["tag"] === "GSO"
                    ? {
                          gsoAktion: gsoAkt ? object["gsoAktion"] : undefined,
                          days: gsoDays ? Number(object["days"]) : undefined,
                          voucher: gsoVoucher ? `${object["voucher"]} ${object["currency"]}` : undefined,
                          deadline: gsoDeadline ? Number(object["deadline"]) : undefined
                      }
                    : {};

            dispatch({
                type: "updateQA",
                question: {
                    questionId: question._id,
                    restId: (question as any).restId,
                    question: question.title === "" ? question.description : question.title,
                    answer: {
                        tab: object["tab"],
                        tag: object["tag"],
                        ...gsoInfo,
                        isCustom: object["chooseCustomPipeline"],
                        customPipeline: object["customPipeline"]
                        // partnerId: object["partnerId"] !== undefined || object["partnerId"] !== "default" ? object["partnerId"] : undefined
                    }
                }
            });
        }
    }, [
        object["tab"],
        object["tag"],
        object["gsoAktion"],
        object["days"],
        object["voucher"],
        object["currency"],
        object["deadline"],
        object["partnerId"],
        object["chooseCustomPipeline"],
        object["customPipeline"]
    ]);

    const [customPipelines, setCustomPipelines] = useState([]);
    useEffect(() => {
        if (!object["chooseCustomPipeline"]) {
            setValue("customPipeline", undefined);
        } else {
            reset({ ...defaultValues, tab: 0, chooseCustomPipeline: true });
            const p = accessedPipelines?.reduce((acc, p) => {
                if (p?.subcategories?.length > 0) acc = [...acc, ...p.subcategories];
                else acc = [...acc, p];
                return acc;
            }, []);
            setCustomPipelines(p);
        }
    }, [object["chooseCustomPipeline"]]);

    return (
        <Grid container justifyContent={"center"}>
            <div className={classes.container}>
                <Grid container alignItems={"center"} spacing={2} style={{ textAlign: "center" }}>
                    {accessedPipelines?.length > 0 && (
                        <Grid item xs={12}>
                            <ControlledCheckbox control={control} name={"chooseCustomPipeline"} label={"Wählen Sie eine benutzerdefinierte Pipeline"} />
                        </Grid>
                    )}

                    {object["chooseCustomPipeline"] ? (
                        <Grid item xs={12}>
                            <ControlledSelect control={control} name={"customPipeline"}>
                                <MenuItem value={undefined} hidden disabled>
                                    Wählen Sie eine benutzerdefinierte Pipeline
                                </MenuItem>
                                {customPipelines?.map(p => (
                                    <MenuItem key={p._id} value={p._id}>
                                        {p.pipelineTitle}
                                    </MenuItem>
                                ))}
                            </ControlledSelect>
                        </Grid>
                    ) : (
                        <Fragment>
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="tab"
                                    render={({ field: { onChange, ...field } }) => (
                                        <Tabs {...field} onChange={(event, value) => onChange(value)} variant="scrollable" scrollButtons="off">
                                            <Tab label="Online Akquise" value={0} className={classes.tabs} classes={{ selected: classes.tabsSelected }} />
                                            <Tab label="Offline Akquise" value={1} className={classes.tabs} />
                                            <Tab label="Partnerschaft" value={2} className={classes.tabs} classes={{ selected: classes.tabsSelected }} />
                                        </Tabs>
                                    )}
                                />
                            </Grid>

                            {/* {object.tab === 0 && ( */}

                            {/* )} */}
                        </Fragment>
                    )}
                    <>
                        <Grid item xs={12}>
                            <Typography align="left" className={classes.label} style={{ marginBottom: -16 }}>
                                Setzen Sie einen Tag für diese Immobilie
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <ControlledSelect control={control} name={"tag"} defaultValue={undefined}>
                                <MenuItem value="Online Bewertung">Online Bewertung</MenuItem>
                                <MenuItem value="Persönliche Bewertung">Persönliche Bewertung</MenuItem>
                                <MenuItem value="Beratung">Beratung</MenuItem>
                                <MenuItem value="GSO">Grand Slam Offer</MenuItem>
                                <MenuItem value="Kaltakquise">Kaltakquise</MenuItem>
                                {tags.map((tag, ind) => (
                                    <MenuItem value={tag} key={ind} style={{ textTransform: textTransformTags.includes(tag) ? "capitalize" : "inherit" }}>
                                        {tag}
                                    </MenuItem>
                                ))}
                            </ControlledSelect>
                        </Grid>

                        {object["tag"] === "GSO" && (
                            <>
                                <Grid item xs={3}>
                                    <Typography align="left" className={classes.label}>
                                        Aktion
                                    </Typography>
                                    <ControlledSelect control={control} name={"gsoAktion"} defaultValue={undefined}>
                                        <MenuItem value="default"> </MenuItem>
                                        <MenuItem value="Frühlingsaktion">Frühlingsaktion</MenuItem>
                                        <MenuItem value="Sommeraktion">Sommeraktion</MenuItem>
                                        <MenuItem value="Herbstaktion">Herbstaktion</MenuItem>
                                        <MenuItem value="Winteraktion">Winteraktion</MenuItem>
                                    </ControlledSelect>
                                </Grid>

                                <Grid item xs={2}>
                                    <Typography align="left" className={classes.label}>
                                        Tage
                                    </Typography>
                                    <ControlledSelect control={control} name={"days"} defaultValue={undefined}>
                                        <MenuItem value="default"> </MenuItem>
                                        <MenuItem value="30">30</MenuItem>
                                        <MenuItem value="60">60</MenuItem>
                                        <MenuItem value="90">90</MenuItem>
                                    </ControlledSelect>
                                </Grid>

                                <Grid item xs={4} style={{ display: "flex", alignItems: "flex-end" }}>
                                    <Grid>
                                        <Typography align="left" className={classes.label}>
                                            Voucher{" "}
                                            <InfoBubble title="Der hier gewählte Wert wird später in Ihren Marketingmails folgendermaßen eingefügt: Wir beteiligen uns mit einem Gutschein in Höhe von (…) an Ihrem nächsten Urlaub!" />
                                        </Typography>
                                        <ControlledNumberInput control={control} type="number" name={"voucher"} />
                                    </Grid>

                                    <Grid style={{ width: 75 }}>
                                        <ControlledSelect control={control} name={"currency"} defaultValue={undefined}>
                                            <MenuItem value="default"> </MenuItem>
                                            <MenuItem value="EUR">EUR</MenuItem>
                                            <MenuItem value="CHF">CHF</MenuItem>
                                        </ControlledSelect>
                                    </Grid>
                                </Grid>

                                <Grid item xs={3}>
                                    <Typography align="left" className={classes.label}>
                                        Frist{" "}
                                        <InfoBubble title="Der hier gewählte Wert wird später in Ihren Marketingmails folgendermaßen eingefügt: Wenn wir Ihre Immobilie nicht innerhalb von (…) Tagen zum gemeinsam festgelegten Verkaufspreis verkaufen, haben Sie keine Kosten." />
                                    </Typography>
                                    <ControlledSelect control={control} name={"deadline"} defaultValue={undefined}>
                                        <MenuItem value="default"> </MenuItem>
                                        <MenuItem value="30">30</MenuItem>
                                        <MenuItem value="60">60</MenuItem>
                                        <MenuItem value="120">120</MenuItem>
                                        <MenuItem value="240">240</MenuItem>
                                    </ControlledSelect>
                                </Grid>
                            </>
                        )}
                    </>

                    {/* {object.tab === 2 && (
                        <>
                            <Grid item xs={12}>
                                <Typography variant={"body2"}>
                                    Wählen Sie einen Netzwerkpartner aus, zu welchem die Immobilie zugeordnet werden soll.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <ControlledSelect control={control} name={"partnerId"} defaultValue={undefined} required={object.tab === 2}>
                                    <MenuItem value="default"> </MenuItem>
                                    {partnersList.map(partner => (
                                        <MenuItem key={partner["_id"]} value={partner["_id"]}>
                                            {partner.meta.name} - {partner.meta.email}
                                        </MenuItem>
                                    ))}
                                </ControlledSelect>
                            </Grid>
                        </>
                    )} */}
                </Grid>
            </div>
        </Grid>
    );
};

export default QuestionPipeline;
