import React, { FC, useState } from "react";
import { Grid } from "@material-ui/core";
import { AddressAnswer, FieldType, QuestionType } from "../types";
import { useLeadGeneratorDispatch } from "../generator-state";
import PlacesAutocomplete from "../helpers/places-autocomplete";
import { QuestionTypeAddress } from "../../smashleads/interfaces/Question";
import { DesignGeneral } from "../../smashleads/interfaces/Design";

interface AddressFieldProps {
    question: QuestionTypeAddress;
    design: DesignGeneral;
    envVar: string;
}

const AddressField: FC<AddressFieldProps> = props => {
    const { question, design, envVar } = props;
    const dispatch = useLeadGeneratorDispatch();
    const [answer, setAnswer] = useState<AddressAnswer | null>(null);

    const onChange = (value: AddressAnswer | null, type?: FieldType) => {
        if (value !== null) {
            dispatch({
                type: "updateQA",
                question: {
                    questionId: question._id,
                    question: question.title === "" ? question.description : question.title,
                    type: QuestionType.ADDRESS,
                    restId: (question as any).restId,
                    answer: { ...value, fieldType: type, hasHouseNumber: value.hasHouseNumber }
                }
            });
            setAnswer({ ...value, fieldType: type });
        }
    };

    return (
        <Grid item xs={12} style={{ marginTop: 8 }}>
            <PlacesAutocomplete question={question} design={design} value={answer} onChange={onChange} envVar={envVar} />
        </Grid>
    );
};

export default AddressField;
