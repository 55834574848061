import { CircularProgress, Grid, makeStyles } from "@material-ui/core";
import { FC, useEffect, useState } from "react";
import { LeadGeneratorStateProvider, useLeadGeneratorState } from "./generator-state";
import { PageLoader } from "./loader";
import { Question, HandleForwardType, QuestionType } from "./types";
import { findEntry } from "./utils";
import { AsyncThunk } from "@reduxjs/toolkit";
import { PipelineDocument, RealEstateDocument } from "@onpreo/database";
import { useRouter } from "next/router";

interface CreateFormProps {
    onFinish?: () => void;
    quiz: any;
    design: any;
    onCreate?: AsyncThunk<any, any, {}>;
    envVar?: string;
    hasValuation: boolean | "enabled" | "disabled";
    defaultContact?: any;
    partnersList?: any[];
    hasAddress?: "enabled" | "disabled";
    onCheckContactExists?: (email: string) => Promise<{ email: string; name: string; phone: string }>;
    accessedPipelines?: PipelineDocument[];
    evaluationType?: "sprengnetter" | "priceHubble";
    preOwner?: { name: string; email: string; phone: string };
    onAfterFinish?: () => void;
    realEstate?: RealEstateDocument;
    workspaceId?: string;
    dispatchFlag?: boolean;
    tags?: string[];
    contactPageRedirect?: boolean;
}

export const CreateForms: FC<CreateFormProps> = ({
    onFinish,
    quiz,
    design,
    onCreate,
    envVar,
    hasValuation,
    defaultContact,
    partnersList,
    hasAddress,
    evaluationType,
    onCheckContactExists,
    accessedPipelines,
    preOwner,
    onAfterFinish,
    realEstate,
    workspaceId,
    dispatchFlag,
    tags,
    contactPageRedirect
}) => {
    return (
        <LeadGeneratorStateProvider>
            <QuestionLoader
                onFinish={onFinish}
                quiz={quiz}
                design={design}
                onCreate={onCreate}
                envVar={envVar}
                hasValuation={hasValuation}
                defaultContact={defaultContact}
                hasAddress={hasAddress}
                partnersList={partnersList}
                onCheckContactExists={onCheckContactExists}
                accessedPipelines={accessedPipelines}
                evaluationType={evaluationType}
                preOwner={preOwner}
                onAfterFinish={onAfterFinish}
                realEstate={realEstate}
                workspaceId={workspaceId}
                dispatchFlag={dispatchFlag}
                tags={tags}
                contactPageRedirect={contactPageRedirect}
            />
        </LeadGeneratorStateProvider>
    );
};

const useGlobalStyles = makeStyles({
    questions: {
        "& .MuiGrid-container": {
            width: "100%",
            marginLeft: 0,
            marginRight: 0
        },
        "& .MuiCard-root": {
            marginBottom: 0
        }
    }
});

const QuestionLoader: FC<CreateFormProps> = ({
    onFinish,
    quiz,
    design,
    onCreate,
    envVar,
    hasValuation,
    defaultContact,
    partnersList,
    hasAddress,
    evaluationType,
    onCheckContactExists,
    accessedPipelines,
    preOwner,
    onAfterFinish,
    realEstate,
    workspaceId,
    dispatchFlag,
    tags,
    contactPageRedirect
}) => {
    const classes = useGlobalStyles();
    const [validQuestions, entryPoint] = findEntry(quiz);
    const questions = quiz.questions;
    const [pageHistory, setPageHistory] = useState<string[]>(() => {
        const nextPage = quiz.questions[entryPoint ?? ""].target as string;
        return [entryPoint, nextPage];
    });
    const [currentQuestion, setCurrentQuestion] = useState<Question | undefined>(undefined);
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const state = useLeadGeneratorState();
    const router = useRouter();

    function handleInitialRender() {
        setCurrentQuestion(questions[pageHistory[pageHistory.length - 1]]);
    }

    const handleForward: HandleForwardType = (next, answer) => {
        const pipeline = state.questions["content_pipeline"]?.answer?.["tab"];
        const isCustom = state.questions["content_pipeline"]?.answer?.["isCustom"];
        const customPipeline = state.questions["content_pipeline"]?.answer?.["customPipeline"];
        const basePath = router.basePath;
        const handleReturn = (path: string) => {
            router.push(path);
            onFinish();
        };
        if (questions[next].type === QuestionType.THANK_YOU) {
            if (onFinish) {
                if (pipeline !== undefined && !isCustom && !contactPageRedirect) {
                    switch (pipeline) {
                        case 0:
                            return handleReturn(`${basePath}/pipelines/setting/online`);
                        case 1:
                            return handleReturn(`${basePath}/pipelines/setting/offline`);
                        case 2:
                            return handleReturn(`${basePath}/pipelines/partnership`);
                        default:
                            return onFinish();
                    }
                } else if (isCustom && !contactPageRedirect) {
                    const p = accessedPipelines.reduce((acc, p) => {
                        if (p?.subcategories?.length > 0) acc = [...acc, ...p.subcategories];
                        else acc = [...acc, p];
                        return acc;
                    }, []);
                    const selectedPipeline = p.find(item => item?._id === customPipeline);
                    return handleReturn(`${basePath}/pipelines/${selectedPipeline?.route}`);
                } else if (contactPageRedirect) {
                    return handleReturn(`${router?.asPath}`);
                }
                onFinish();
            }
            if (onAfterFinish) {
                onAfterFinish();
            }
            return;
        }

        const nextQuestion: string | undefined = next;

        setPageHistory([...pageHistory, next]);
        setTimeout(() => setCurrentQuestion(questions[next]), 0);
    };

    const handleBackward = () => {
        const newPageHistory = (() => {
            for (let i = 2; i < pageHistory.length; i++) {
                if (questions[pageHistory[pageHistory.length - i]].type !== QuestionType.LOADING) return pageHistory.slice(0, -i + 1);
            }
            return pageHistory.slice(0, -1);
        })();

        setPageHistory(newPageHistory);
        setTimeout(() => setCurrentQuestion(questions[newPageHistory[newPageHistory.length - 1]]), 0);
    };

    useEffect(() => {
        handleInitialRender();
    }, []);
    if (!currentQuestion)
        return (
            <Grid container justify={"center"}>
                <CircularProgress color={"secondary"} size={24} />
            </Grid>
        );

    return (
        <div className={classes.questions}>
            <PageLoader
                page={currentQuestion!}
                pageNumber={pageHistory.length - 1}
                handleForward={handleForward}
                handleBackward={handleBackward}
                isMobile={isMobile}
                design={design}
                onCreate={onCreate}
                envVar={envVar}
                hasValuation={hasValuation}
                defaultContact={defaultContact}
                hasAddress={hasAddress}
                partnersList={partnersList}
                onCheckContactExists={onCheckContactExists}
                accessedPipelines={accessedPipelines}
                evaluationType={evaluationType}
                preOwner={preOwner}
                realEstate={realEstate}
                workspaceId={workspaceId}
                dispatchFlag={dispatchFlag}
                tags={tags}
            />
        </div>
    );
};

// export default CreateForms;
